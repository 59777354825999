import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import Image from "../../lib/UI/Image";
import {rgba} from "polished";
import Text from "../../lib/UI/Text";

const First__Event__Holder = styled(FlexBox)`
  flex-direction: column;
  min-height: calc(100vh - 58px);
  margin-bottom: 30px;
`
const First__Event__Main__Image = styled(Image)`
  width: 100%;
  object-fit: contain;
  background-color: ${({theme}) => theme.backgroundColor};
  border-bottom: 1px solid ${rgba('#fff', 0.3)};

  @media (min-width: 1100px) {
    max-height: 550px;
  }
`
const First__Event__Name__Title = styled(Text)`
  font-size: 24px;
  font-weight: bold;
  padding: 25px 10px;
  text-align: center;
`
const First__Event__Content = styled(Text)`
  padding: 0 10px;
  font-size: 22px;
`
const Figth__Cards__Holder = styled(FlexBox)`
  flex-direction: initial;
  gap: 20px;
`
const Figth__Card = styled(Image)`
  max-width: 500px;
  width: calc(50% - 10px);
  flex-shrink: 0;
  margin: 0 auto;
`
const Buy__Ticket = styled(FlexBox)`
  cursor: pointer;


`
const Buy__Ticket__Label = styled(Text)`
  font-weight: bold;
  font-size: 26px;
  padding: 15px 20px;
  max-width: 400px;
  border-radius: 15px;
  border: 1px solid ${({theme}) => theme.color};
  margin: 20px auto;
  transition: 500ms;

  &:hover {
    color: ${({theme}) => theme.backgroundColor};
    background-color: ${({theme}) => theme.color};
    border-color: ${({theme}) => theme.backgroundColor};;
  }
`
const Main__Fight__Card = styled(Image)`
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
`
const Main_Event__Title = styled(Text)`
  font-weight: bold;
  font-size: 24px;
  padding: 10px;
  text-align: center;
`
const Tournament__Fight__Card__Holder = styled(FlexBox)`
  flex-direction: column;
  gap: 20px;
`
const Content__Holder = styled(FlexBox)`
  flex-direction: column;
  background-color: ${({theme}) => theme.backgroundColor};
`


export const Styled = {
    Content__Holder,
    Tournament__Fight__Card__Holder,
    Main_Event__Title,
    Main__Fight__Card,
    Buy__Ticket__Label,
    Buy__Ticket,
    Figth__Card,
    Figth__Cards__Holder,
    First__Event__Content,
    First__Event__Name__Title,
    First__Event__Main__Image,
    First__Event__Holder
}