import {Styled} from "./FirstEvent.styles"
import PageHolder from "../../lib/UI/PageHolder";
import usePageContent from "../../lib/PageContent";
import {useTranslation} from "react-i18next";

const FirstEvent = () => {
    const {firstEvent, BuyTicket} = usePageContent()

    const {t} = useTranslation()

    const {
        mainImage,
        mainCard,
        tournamentCards,
        fightCards
    } = firstEvent

    return (
        <Styled.First__Event__Holder>
            <PageHolder>
                <Styled.Content__Holder>
                    <Styled.First__Event__Main__Image
                        onClick={BuyTicket}
                        src={mainImage}
                    />
                    <Styled.First__Event__Name__Title>
                        Gala Amateur Combat League 2
                    </Styled.First__Event__Name__Title>
                    <Styled.First__Event__Content>
                        {t('lbl_acl2_desc').split('<BREAK>').map((content: string) => (<>{content}<br/><br/></>))}
                    </Styled.First__Event__Content>
                    <Styled.First__Event__Name__Title>
                        {t('lbl_main_event')}
                    </Styled.First__Event__Name__Title>
                    <Styled.Main__Fight__Card
                        src={mainCard.image}
                    />
                    <Styled.First__Event__Name__Title>
                        {t('lbl_other_events')}
                    </Styled.First__Event__Name__Title>
                    <Styled.Figth__Cards__Holder>
                        {fightCards?.map((item: string) => (
                            <Styled.Figth__Card
                                key={item}
                                src={item}
                            />
                        ))}
                    </Styled.Figth__Cards__Holder>
                    <Styled.First__Event__Name__Title>
                        {t('lbl_tournament_list')}
                    </Styled.First__Event__Name__Title>
                    <Styled.Tournament__Fight__Card__Holder>
                        {tournamentCards.map((item: any) => (
                            <Styled.Main__Fight__Card
                                src={item}
                            />
                        ))}
                    </Styled.Tournament__Fight__Card__Holder>
                    <Styled.First__Event__Name__Title>
                        {t('lbl_buy_ticket_for_gala')}
                    </Styled.First__Event__Name__Title>
                    <Styled.Buy__Ticket
                        onClick={BuyTicket}
                    >
                        <Styled.Buy__Ticket__Label>
                            {t('lbl_buy_ticket')}
                        </Styled.Buy__Ticket__Label>
                    </Styled.Buy__Ticket>
                </Styled.Content__Holder>
            </PageHolder>
        </Styled.First__Event__Holder>
    )
}

export default FirstEvent