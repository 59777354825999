import UpcomingEvents from "./UpcomingEvents";
import FlexBox from "../../lib/UI/FlexBox";
import Sponsors from "../Sponsors";
import ImageGallery from "./ImageGallery";
import {useTranslation} from "react-i18next";
import usePageContent from "../../lib/PageContent";
import Image from "../../lib/UI/Image";
import PageHolder from "../../lib/UI/PageHolder";

const MainPage = () => {

    const {t} = useTranslation()

    const {imagesList, BuyTicket} = usePageContent()
    return (
        <FlexBox
            style={{
                flexDirection: 'column',
                flexWrap: 'nowrap'
            }}
        >
            <ImageGallery
                hideDots={true}
                list={imagesList}
            />
            <UpcomingEvents/>
            <PageHolder>
                <Image
                    onClick={BuyTicket}
                    src={window.location.origin + '/baner_ACL2.jpeg'}
                />
            </PageHolder>
            {/*// <MainPageNewsSeller/>*/}
            <Sponsors
                isMainPage={true}
            />
        </FlexBox>
    )
}

export default MainPage